import { isWithinInterval } from 'date-fns'
export default function({ app, redirect }) {
  if (
    !isWithinInterval(
      new Date(), // Now
      {
        start: new Date('2023-06-21 00:00:00 GMT+07:00'.replace(/-/g, '/')),
        end: new Date('2023-07-31 23:59:59 GMT+07:00'.replace(/-/g, '/'))
      }
    )
  ) {
    return redirect('/not-found')
  } else if (!app.$features('DWB-729-DDO-RETENTION-CAMPAIGN-F')) {
    return redirect('/not-found')
  }
}
