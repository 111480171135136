import Vue from 'vue'
export const tags = () => ({
  track: {
    pageView() {
      // eslint-disable-next-line no-eval
      eval(`_lt('send', 'pv', ['179fae12-9de5-45be-9995-4c2068603b2b'])`)
    },
    conversion() {
      // eslint-disable-next-line no-eval
      eval(
        `_lt('send', 'cv', {type: 'Conversion'},['179fae12-9de5-45be-9995-4c2068603b2b'])`
      )
    }
  }
})

let isTag = false

Vue.mixin({
  mounted() {
    if (!isTag) {
      tags().track.pageView()
      isTag = true
    }
  }
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function routeHook(to, from) {
  if (isTag) isTag = false
}
export type lineTags = ReturnType<typeof tags>

export default function({ app }, inject) {
  // Every time the route changes (fired on initialization too)
  app.router.afterEach(routeHook)
  inject('lineTags', tags())
  return app
}
