import { isBefore } from 'date-fns'
export default function({ app, redirect }) {
  const currentDate = new Date()
  const startDate = new Date('2024-01-01 00:00:00 GMT+07:00'.replace(/-/g, '/'))

  // forever campaign
  if (isBefore(currentDate, startDate)) {
    return redirect('/not-found')
  } else if (!app.$features('DWB-984-BTL-C4C-DEALER-REFER-MC-F')) {
    return redirect('/not-found')
  }
}
