import { isWithinInterval } from 'date-fns'
export default function({ app, redirect }) {
  if (
    !isWithinInterval(
      new Date(), // Now
      {
        start: new Date('2023-11-01 00:00:00 GMT+07:00'.replace(/-/g, '/')),
        end: new Date('2023-12-30 23:59:59 GMT+07:00'.replace(/-/g, '/'))
      }
    )
  ) {
    return redirect('/not-found')
  } else if (!app.$features('DWB-960-TRUE-TWENTY-YEARS-CAMPAIGN-Q4-F')) {
    return redirect('/not-found')
  }
}
