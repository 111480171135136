import { VehicleSubType } from '~/domains/calculator/types'

export type PreferredVehicleState = {
  preferredVehicle: VehicleSubType | null
}

export const state = (): PreferredVehicleState => ({
  preferredVehicle: null
})

export const mutations = {
  PREFER_MOTORBIKE(state: PreferredVehicleState) {
    state.preferredVehicle = VehicleSubType.NMC
  },
  PREFER_BIG_BIKES(state: PreferredVehicleState) {
    state.preferredVehicle = VehicleSubType.BB
  },
  PREFER_CAR(state: PreferredVehicleState) {
    state.preferredVehicle = VehicleSubType.CAR
  },
  RESET(state: PreferredVehicleState) {
    state.preferredVehicle = null
  }
}

export const getters = {
  preferredVehicle: state => state.preferredVehicle
}

export default {
  state,
  mutations,
  getters
}
