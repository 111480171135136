import { isWithinInterval, startOfDay, endOfDay } from 'date-fns'
export default function({ app, redirect }) {
  if (
    !isWithinInterval(
      new Date(), // Now
      {
        start: startOfDay(new Date('2021-12-10')),
        end: endOfDay(new Date('2022-12-10'))
      }
    )
  ) {
    return redirect('/not-found')
  } else if (!app.$features('DWB-107-BTL-AUTO-ONE-F')) {
    return redirect('/not-found')
  }
}
