import Vue from 'vue'
import VueAnalytics from 'vue-analytics'

export default ({ app }) => {
  Vue.use(VueAnalytics, {
    id: app.$env.GOOGLE_ANALYTICS_ID,
    router: app.router,
    autoTracking: {
      pageviewTemplate(route) {
        return {
          page: route.path,
          title: document.title,
          location: window.location.href
        }
      }
    }
  })
}
