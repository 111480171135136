import Vue from 'vue'
import amplitude from 'amplitude-js'
import { LoanInfo } from '~/domains/calculator/types'
export default ({ app }) => {
  const chekConsentCookie: any = checkCookie(
    'OptanonConsent',
    'groups',
    'C0002'
  )
  window.onload = () => {
    const el = document.getElementById('onetrust-accept-btn-handler')
    const elAll = document.getElementById('accept-recommended-btn-handler')
    const elAccept = document.getElementsByClassName(
      'save-preference-btn-handler'
    )
    addAction(el)
    addAction(elAll)
    addAction(elAccept[0])
  }
  if (chekConsentCookie === '1') {
    amplitude.getInstance().init(app.$env.AMPLITUDE_API_KEY, null, {
      includeUtm: true,
      includeReferrer: true,
      includeGclid: true
    })
  }
  const project = amplitude.getInstance()
  Vue.prototype.$analytics = initAnalytics(project)
}

const addAction = (el: any) => {
  if (el) el.addEventListener('click', () => window.location.reload())
}
const initAnalytics = project => ({
  homePage: {
    land() {
      project.logEvent('Land on home page')
    },
    clickOnCarousel(logTitle: string, value: {}) {
      project.logEvent(logTitle, value)
    }
  },
  selectVehicleModal: {
    open(from: { source: string; action: string }) {
      project.logEvent('land on select modal vehicle', from)
    }
  },
  vehicleSearchPage: {
    // land() {
    //   project.logEvent('land on vehicle search page')
    // },
    selectVehicle(vehicle: Vehicle) {
      project.logEvent('Select vehicle', vehicle)
    },
    landOnVehicle(from: { source: string; action: string }) {
      project.logEvent('land on vehicle search page', from)
    },
    landOnVehicleWithOptional(from: {
      source: string
      action: string
      vehicleType: string
    }) {
      project.logEvent('land on vehicle search page', from)
    }
  },
  calculatorPage: {
    land() {
      project.logEvent('Land on loan calculator page')
    },
    defaultLoanCalculation(loanInfo: LoanInfo) {
      project.logEvent('Default loan calculation', loanInfo)
    },
    changeLoanInfo(loanInfo: LoanInfo) {
      project.logEvent('Change loan information', loanInfo)
    },
    acceptCalculatedLoanResult(loanInfo: LoanInfo) {
      project.logEvent('Accept calculated loan result', loanInfo)
    },
    acceptCalculatedLoanResultWithCalValue(form: {
      source: string
      action: string
      vehiclesubtype: string
      loantype: string
      appraisalprice: string
      requestedLoanAmount: string
      term: string
      installmentAmount: string
    }) {
      project.logEvent('Accept calculated loan result', form)
    },
    landLoanCal(from: { source: string; action: string; vehicleType: string }) {
      project.logEvent('land on loan calculator page', from)
    },
    selectProduct(from: { action: string }) {
      project.logEvent('click to select product', from)
    }
  },
  leadFormPage: {
    land() {
      project.logEvent('Land on lead form page')
    },
    landV2() {
      project.logEvent('Land on lead form v2 page')
    },
    dropLead(address: { province: string; zipCode: string }) {
      project.logEvent('Drop lead', address)
    },
    verifiedByReCaptcha(props: { verificationElaspedTime: number }) {
      project.logEvent('Successfully verified by ReCaptcha', props)
    },
    failToDropLead(from: { cause: string; source: string }) {
      project.logEvent('Fail to drop lead', from)
    },
    landforms(from: { source: string; action: string }) {
      project.logEvent('land on lead form', from)
    },
    submitDroplead(from: { utmCampaign: string }) {
      project.logEvent('submit lead form campaign', from)
    },
    submitOnedayCampaign(from: {
      source: string
      action: string
      subCampaign: any
    }) {
      project.logEvent('submit lead form one day campaign', from)
    }
  },
  thankYouPage: {
    land(from: {
      brand: string
      model: string
      year: string
      gear: string
      engine: string
      submodel: string
      numberofwheels: string
      vehiclesubtype: string
      loantype: string
      caramount: string
      requestamount: string
      loanperiod: string
      installamount: string
      eventleadform: string
      referral: string // to add value either "employee" or "TeleSale"
      events: string
      utmCampaign: string
      oriCampaign: string
    }) {
      project.logEvent('land on thank you page', from)
    }
  },
  aboutUsPage: {
    land(from: { source: string; action: string }) {
      project.logEvent('Land on about us page', from)
    }
  },
  contactUsPage: {
    land(from: { source: string; action: string }) {
      project.logEvent('Land on contact us page', from)
    }
  },
  floodPage: {
    land() {
      project.logEvent('Land flood')
    }
  },
  error: {
    land() {
      project.logEvent('Error Page')
    }
  },
  c4cyoucan2_2019: {
    land() {
      project.logEvent('Land promotion 84 month')
    }
  },
  c4c_2w_borntobePage: {
    land() {
      project.logEvent('Land promotion 2 wheel')
    }
  },
  c4c_borntobePage: {
    land() {
      project.logEvent('Land promotion 4 wheel')
    }
  },
  faqPage: {
    land(from: { source: string; action: string }) {
      project.logEvent('Land on faq page', from)
    }
  },
  productSelectPage: {
    land() {
      project.logEvent('Land on product select page')
    }
  },
  termsAndConditionsPage: {
    land() {
      project.logEvent('Land on terms and conditions page')
    }
  },
  covid19Page: {
    land() {
      project.logEvent('Land on covid 19 page')
    },
    landforms(from: { source: string; action: string }) {
      project.logEvent('land on covid 19', from)
    }
  },

  memberGetMemberPage: {
    land() {
      project.logEvent('Land on Member Get Member Page')
    },
    landforms(from: { source: string; action: string }) {
      project.logEvent('land on Member Get Member', from)
    }
  },
  productCarPage: {
    land() {
      project.logEvent('Land on product car page')
    },
    heroEstimationCarClick() {
      project.logEvent(
        'Click from estimation loan button hero section in car page'
      )
    },
    heroEstimationBbClick() {
      project.logEvent(
        'Click from estimation loan button hero section in bigbike page'
      )
    },
    heroEstimationNmcClick() {
      project.logEvent(
        'Click from estimation loan button hero section in motorbike page'
      )
    },
    loanRequestBbClick() {
      project.logEvent('Click from loan request button bottom in bigbike page')
    },
    loanRequestNmcClick() {
      project.logEvent(
        'Click from loan request button bottom in motorbike page'
      )
    },
    landCarProduct(from: { source: string; action: string }) {
      project.logEvent('land car product', from)
    }
  },
  productMotorbikePage: {
    land() {
      project.logEvent('Land on product motorbike page')
    },
    landMotobikeProduct(from: { source: string; action: string }) {
      project.logEvent('land NMC product', from)
    }
  },
  productBigBikesPage: {
    land() {
      project.logEvent('Land on product big bikes page')
    },
    landBigBikesProduct(from: { source: string; action: string }) {
      project.logEvent('land BB product', from)
    }
  },
  purposeBusinessExpansionPage: {
    land(from: { source: string; action: string }) {
      project.logEvent('land on purpose business expansion page', from)
    }
  },
  purposeHouseRenovationPage: {
    land(from: { source: string; action: string }) {
      project.logEvent('land on purpose house renovation page', from)
    }
  },
  purposePayoffCreditPage: {
    land(from: { source: string; action: string }) {
      project.logEvent('land on purpose payoff credit page', from)
    }
  },
  purposeTuitionFeePage: {
    land(from: { source: string; action: string }) {
      project.logEvent('land on purpose tuition fee page', from)
    }
  },
  carouselCarPage: {
    land() {
      project.logEvent('Carousel Car Page')
    }
  },
  carouselBigBikePage: {
    land() {
      project.logEvent('Carousel Big Bike Page')
    }
  },
  carouselMemberPage: {
    land() {
      project.logEvent('Carousel Member Get Member Page')
    }
  },
  promotionsPage: {
    land(from: { source: string; action: string }) {
      project.logEvent('Land on promotions page', from)
    },
    clickOnLoanRequestButton(eventName: string, eventProps: {}) {
      project.logEvent(eventName, eventProps)
    }
  },
  announcementPage: {
    land(from: { source: string; action: string }) {
      project.logEvent('land on announcement', from)
    }
  },

  belowthelinePages: {
    land(from: { source: string }) {
      project.logEvent('land on blt lead form ', from)
    },
    clickSubmitLead(from: { source: string; action: string }) {
      project.logEvent('submit blt lead form', from)
    }
  },
  belowthelineGobearPage: {
    land() {
      project.logEvent('land on blt lead form gobear')
    }
  },
  angpaoPromotionPage: {
    landforms(from: { source: string; action: string }) {
      project.logEvent('land on angpao page', from)
    }
  },
  ewinsurancePage: {
    landforms(from: { source: string; action: string }) {
      project.logEvent('land on ew insurance page', from)
    }
  },
  deliveryPage: {
    landforms(from: { source: string; action: string }) {
      project.logEvent('land on delivery promotions page', from)
    }
  },
  onlineCampaignPage: {
    landforms(from: { source: string; action: string }) {
      project.logEvent('land on cost relief promotions page', from)
    }
  },
  scholarshipAnnouncementPage: {
    landforms(from: { source: string; action: string }) {
      project.logEvent('land on scholarship announcement promotions page', from)
    }
  },
  moveToCar4cashCampaignPage: {
    landforms(from: { source: string; action: string }) {
      project.logEvent('land on move to car4cash promotions page', from)
    }
  },
  OneDayCampaignPage: {
    landforms(from: { source: string; action: string; subCampaign: string }) {
      project.logEvent('land on car4cash 1 day promotions page', from)
    }
  },
  GoAppDownload: {
    click(from: { source: string; action: string }) {
      project.logEvent('go app download', from)
    }
  },
  EwProtectCampaignPage: {
    landforms(from: { source: string; action: string }) {
      project.logEvent('land on auto parts protection promotions page', from)
    }
  },
  EwProtectV2CampaignPage: {
    landforms(from: { source: string; action: string }) {
      project.logEvent('land on c4c protect promotions page', from)
    }
  },
  Bigbike100CampaignPage: {
    landforms(from: { source: string; action: string }) {
      project.logEvent('land on bigbike 100 promotions page', from)
    }
  },
  FoodPandaCampaignPage: {
    landforms(from: { source: string; action: string }) {
      project.logEvent('land on 2W free e coupon promotions page', from)
    }
  },
  BackToSchoolCampaignPage: {
    landforms(from: { source: string; action: string }) {
      project.logEvent('land on back to school may 2021 promotion page', from)
    }
  },
  AlcoholSprayCampaignPage: {
    landforms(from: { source: string; action: string }) {
      project.logEvent('land on spray alcohol 2021', from)
    }
  },
  Blogs: {
    land(from: { source: string; action: string }, event) {
      project.logEvent(event, from)
    }
  },
  WelcomeBackCampaignPage: {
    landforms(from: { source: string; action: string }) {
      project.logEvent('land on welcome back campaign promotions page', from)
    }
  },
  SmileUvCampaignPage: {
    landforms(from: { source: string; action: string }) {
      project.logEvent('land on Smile promotions page', from)
    }
  },
  RevolvingLoadCampaign: {
    landforms(from: { source: string; action: string }) {
      project.logEvent('land on revolving loan promotions page', from)
    }
  },
  PromptChaiCampaign: {
    landforms(from: { source: string; action: string }) {
      project.logEvent('land on c4c promptchai rehit 22 promotions page', from)
    }
  },
  TwoWheelPttVoucherCampaignPage: {
    landforms(from: { source: string; action: string }) {
      project.logEvent('land on ptt voucher 2021 promotions page', from)
    }
  },
  prochamberCampaignPage: {
    landforms(from: { source: string; action: string }) {
      project.logEvent('land on prochamber24_c4c promotions page', from)
    }
  },
  promotionDetail: {
    landforms(
      from: { source: string; action: string; subCampaign?: undefined },
      campaignName
    ) {
      const label = 'land on ' + campaignName + ' promotions page'
      project.logEvent(label, from)
    }
  },
  Twentyyears23Q2Campaign: {
    landforms(from: { source: string; action: string }) {
      project.logEvent('land on c4c 20years voucher 23q2 promotions page', from)
    }
  },
  vehicleSelectPage: {
    landforms(from: { source: string; action: string }) {
      project.logEvent('land on vehicle select page', from)
    },
    change(from: { action: string }) {
      project.logEvent('Change vehicle selection', from)
    },
    select(from: {
      vehicleType: string
      numberOfWheels: string
      brand: string
      model: string
      year: string
      gear: string
      engine: string
      subModel: string
    }) {
      project.logEvent('Select vehicle', from)
    }
  },
  loanCalculationPage: {
    land(from: { source: string; action: string }) {
      project.logEvent('land on loan calculation page', from)
    },
    default(from: {
      requestedLoanAmount: string
      term: string
      installmentAmount: string
    }) {
      project.logEvent('Default loan calculation', from)
    }
  }
})

type Vehicle = {
  numberOfWheels: number
  brand: string
  model: string
  year: string
  vehiclesubtype: string
}

export type Analytics = ReturnType<typeof initAnalytics>

function getCookie(cname) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  // console.log(ca)
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

function checkCookie(name, sname, condition) {
  let cname = ''
  cname = getCookie(name)
  if (cname !== '') {
    // console.log('Welcome again ' + user)
    const ca = cname.split('&')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(sname) === 0) {
        // return c.substring(sname.length, c.length)
        const v = c.substring(sname.length + 1, c.length).split(',')
        let av = ''
        v.map(r => {
          const aa = r.split(':')
          if (aa[0] === condition) {
            av = aa[1]
          }
        })
        return av
        // const av = []
        // v.map(r => {
        //   const aa = r.split(':')
        //   if (aa[0] === 'C0001') {
        //     // console.log(
        //     //   'คุกกี้ที่มีความจำเป็นต่อการใช้งานเว็บไซต์ = ',
        //     //   aa[1]
        //     // )
        //     av['คุกกี้ที่มีความจำเป็นต่อการใช้งานเว็บไซต์'] = aa[1]
        //   } else if (aa[0] === 'C0002') {
        //     // console.log(
        //     //   'คุกกี้เพื่อเพิ่มประสิทธิภาพในการใช้งานเว็บไซต์ = ',
        //     //   aa[1]
        //     // )
        //     av['คุกกี้เพื่อเพิ่มประสิทธิภาพในการใช้งานเว็บไซต์'] = aa[1]
        //   } else if (aa[0] === 'C0004') {
        //     // console.log('คุกกี้เพื่อการวิเคราะห์ข้อมูล = ', aa[1])
        //     av['คุกกี้เพื่อการวิเคราะห์ข้อมูล'] = aa[1]
        //   } else {
        //     console.log('No Data')
        //   }
        // })
        // console.table(av)
        // console.log(v)
      }
    }
  }
}
