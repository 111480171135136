/* eslint-disable */
export default ({ app }, inject) => {
  !(function(f, b, e, v, n, t, s) {
    if (f.fbq) return
    n = f.fbq = function() {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    }
    if (!f._fbq) f._fbq = n
    n.push = n
    n.loaded = !0
    n.version = '2.0'
    n.queue = []
    t = b.createElement(e)
    t.async = !0
    t.src = v
    s = b.getElementsByTagName(e)[2]
    s.parentNode.insertBefore(t, s)
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js'
  )
  let listAgencyName = []
  if (app.$env.FACEBOOK_PIXEL_ID) {
    const PIXEL = app.$env.FACEBOOK_PIXEL_ID
      ? app.$env.FACEBOOK_PIXEL_ID.split(',')
      : []
    PIXEL.forEach(val => {
      const listAgency = val.split('=').map(v => {
        return v
      })
      listAgencyName[listAgency[0]] = listAgency[1]

      fbq('init', listAgency[1])
    })
    fbq('track', 'PageView')
    inject('fbq', fbq)
    inject('agency', listAgencyName)
  }
}
