

























import Vue from 'vue'
import ContentContainer from '~/components/commons/bases/content-container/content-container.vue'
import WithLoading from '~/components/commons/enhancers/with-loading.vue'
import { Caption } from '~/components/commons/bases/typography'
import Picture from '~/components/commons/bases/picture/picture.vue'
import { getCurrentYearTH } from '~/services/utils'

export default Vue.extend({
  components: {
    ContentContainer,
    WithLoading,
    Caption,
    Picture
  },
  data() {
    return {
      featureTigTok: this.$features('DWB-638-UPDATE-FOOTER-F'),
      getCurrentYearTH: getCurrentYearTH()
    }
  }
})
