import { isWithinInterval, isAfter } from 'date-fns'
import { isEmpty, find } from 'lodash'
import Promotions from '~/pages/promotions/configs-promotions/index'

export const isOnTime = ({ publishUp, publishDown, publishUpProd }, env) => {
  let publishStart = publishUp
  if (env.ENVIRONMENT_NAME === 'prod') {
    publishStart = publishUpProd
  }

  const start = `${publishStart} 00:00:00 GMT+07:00`.replace(/-/g, '/')
  if (isEmpty(publishDown)) {
    return isAfter(new Date(), new Date(start))
  } else {
    const down = `${publishDown} 23:59:59 GMT+07:00`.replace(/-/g, '/')
    return isWithinInterval(
      new Date(), // Now
      {
        start: new Date(start),
        end: new Date(down)
      }
    )
  }
}

export default (campaignName, app, redirect) => {
  const promotion = find(Promotions, { dataTest: campaignName })
  if (!isOnTime(promotion, app.$env)) {
    return redirect('/not-found')
  } else if (
    promotion.featureToggle === true
      ? false
      : promotion.featureToggle === false
      ? true
      : !app.$features(promotion.featureToggle)
  ) {
    return redirect('/not-found')
  }
}
