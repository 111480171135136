import { isAfter } from 'date-fns'
export default function({ app, redirect }) {
  if (
    !isAfter(
      new Date(), // Now
      new Date('2021-10-01 00:00:00 GMT+07:00'.replace(/-/g, '/'))
    )
  ) {
    return redirect('/not-found')
  } else if (!app.$features('DWB-418-CAMPAIGN-DEALER-REFER-F')) {
    return redirect('/not-found')
  }
}
