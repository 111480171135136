import { isWithinInterval } from 'date-fns'
export default function({ app, redirect }) {
  if (
    !isWithinInterval(
      new Date(), // Now
      {
        start: new Date('2024-09-26 00:00:00 GMT+07:00'.replace(/-/g, '/')),
        end: new Date('2025-01-31 23:59:59 GMT+07:00'.replace(/-/g, '/'))
      }
    )
  ) {
    return redirect('/not-found')
  } else if (!app.$features('DWB-1475-KMA-ONE-RETAIL-X-DDO-F')) {
    return redirect('/not-found')
  }
}
