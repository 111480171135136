import { Dictionary } from 'vue-router/types/router'
import { isValue } from '../domains/utils'
import customRoutes from '~/constants/customPath'
const CAMPAIGN_KEYS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_product',
  'referral_code',
  'ori_campaign'
]

function isNeedToBeCarryOriCampaign(
  to: Dictionary<string | string[]> = {},
  from: Dictionary<string | string[]> = {}
): void {
  /* eslint-disable */
  if (
    to.path.includes('/promotions/') &&
    isValue(from.query['ori_campaign']) === false
  ) {
    for (const key in customRoutes) {
      for (let campaign = 0; campaign < customRoutes[key].length; campaign++) {
        if (to.path === customRoutes[key][campaign].path) {
          /* eslint-disable */
          from.query['ori_campaign'] = customRoutes[key][campaign].utmCampaign
        }
      }
    }
  }
}

function isNeedToBeCarry(
  fromQuery: Dictionary<string | string[]> = {},
  toQuery: Dictionary<string | string[]> = {}
): boolean {
  return !!CAMPAIGN_KEYS.find(
    key => isValue(fromQuery[key]) && !isValue(toQuery[key])
  )
}

function getCarryCampaignDataObj(
  fromQuery: Dictionary<string | string[]> = {}
): Dictionary<string | string[]> {
  return CAMPAIGN_KEYS.filter(key => isValue(fromQuery[key])).reduce(
    (obj, key) => ({ ...obj, [key]: fromQuery[key] }),
    {}
  )
}

export function carryHook(to, from, next) {
  isNeedToBeCarryOriCampaign(to, from)
  if (isNeedToBeCarry(from.query, to.query)) {
    return next({
      ...to,
      query: {
        ...getCarryCampaignDataObj(from.query),
        ...to.query
      }
    })
  }

  return next()
}

export default ({ app }) => {
  app.router.beforeEach(carryHook)
}
