export const paramsSerializerWithForcedPercentEncoding = params => {
  const parts = Object.entries(params).map(entry => {
    const [key, value]: [any, any] = entry
    return escape(key) + '=' + escape(value)
  })

  return parts.join('&')
}

export const convertExtensionToWebp = (param: string): string => {
  return param.replace('.jpg', '.webp').replace('.png', '.webp')
}

export const pushOriCampaign = (
  utmCampaign: string,
  router: any,
  query: any
) => {
  if (!query.ori_campaign) {
    router.push({
      query: { ori_campaign: utmCampaign, ...query }
    })
  }
}

export const getCurrentYearTH = () => {
  const date = new Date().toLocaleDateString('en-EN', { year: 'numeric' })
  const currentYear = parseInt(date) + 543

  return currentYear
}
