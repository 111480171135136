import Vue from 'vue'

Vue.prototype.$ddLogs = window.DD_LOGS

export default ({ app }) => {
  if (window.DD_LOGS) {
    const DD_LOGS = window.DD_LOGS

    DD_LOGS.init({
      clientToken: app.$env.DD_PUBLIC_API_KEY,
      forwardErrorsToLogs: true
    })
    DD_LOGS.addLoggerGlobalContext('environment', app.$env.ENVIRONMENT_NAME)
  }
}
