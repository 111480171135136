






import Vue from 'vue'
import LayoutDefault from '~/layouts/default.vue'
import WithLoading from '~/components/commons/enhancers/with-loading.vue'
export default Vue.extend({
  components: { LayoutDefault, WithLoading }
})
