import Vue from 'vue'

const time = {
  now() {
    return new Date()
  }
}

Vue.prototype.$time = time

export type Time = typeof time
