






















import Vue from 'vue'
import { TextBody } from '~/components/commons/bases/typography'

import SectionContainer from '~/components/layouts/section-container/section-container.vue'
export default Vue.extend({
  components: { TextBody, SectionContainer },
  props: {
    error: {
      type: Object,
      requried: true,
      default: () => {}
    }
  },
  computed: {
    content() {
      // @ts-ignore
      switch (this.error.statusCode) {
        case 404:
          return {
            title: 'ขออภัย ไม่พบหน้าเว็บไซต์ที่คุณต้องการ',
            description: 'กรุณาตรวจสอบ URL หรือข้อมูลที่คุณต้องการค้นหาอีกครั้ง'
          }
        default:
          return {
            title: 'ขออภัย ระบบเกิดความขัดข้อง',
            description: 'กรุณาลองใหม่อีกครั้ง'
          }
      }
    }
  },
  head() {
    return {
      title: 'Page Not Found'
    }
  },
  mounted() {
    // @ts-ignore
    this.$analytics.error.land()
  }
})
