import { isWithinInterval, startOfDay, endOfDay } from 'date-fns'

const conf = {
  start: startOfDay(new Date('2019-12-01')),
  end: endOfDay(new Date('2019-12-06'))
}

export const makeSplashPagePlugin = conf => ({
  isShow: (date: Date = new Date()) => {
    return isWithinInterval(date, {
      start: conf.start,
      end: conf.end
    })
  }
})

export type SplashPage = ReturnType<typeof makeSplashPagePlugin>
export default function({ app }, inject) {
  inject('splashPage', makeSplashPagePlugin(conf))
  return app
}
