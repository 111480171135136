import throttle from 'lodash/throttle'
import Vue from 'vue'
import ResizeObserver from 'resize-observer-polyfill'

type Data = {
  observer?: ResizeObserver
  isMobileViewPort: boolean
  isTabletViewPort: boolean
  isDesktopViewPort: boolean
}

const ShellMixin = Vue.extend({
  data(): Data {
    return {
      observer: undefined,
      isMobileViewPort: false,
      isTabletViewPort: false,
      isDesktopViewPort: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.observer = new ResizeObserver(this.onResize())
      this.observer.observe(this.$root.$el)
    })
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.unobserve(this.$root.$el)
    }
  },
  methods: {
    detectIsMobile() {
      this.isMobileViewPort = window.matchMedia('(max-width: 767px)').matches
    },
    detectIsTablet() {
      this.isTabletViewPort = window.matchMedia(
        '(min-width: 768px) and (max-width: 1365px)'
      ).matches
    },
    detectIsDesktop() {
      this.isDesktopViewPort = window.matchMedia('(min-width: 1366px)').matches
    },
    onResize() {
      return throttle(() => {
        this.detectIsMobile()
        this.detectIsTablet()
        this.detectIsDesktop()
      }, 200)
    }
  }
})

export default ShellMixin
