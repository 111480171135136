import Vue from 'vue'
import VueGtag from 'vue-gtag'

export default ({ app }) => {
  Vue.use(VueGtag, {
    config: {
      id: app.$env.GOOGLE_ANALYTICS4_ID
    }
  })
}
